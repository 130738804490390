import React from 'react';
import Layout from '../components/layout/Layout';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';

const Datenschutz = ({data}) => (
  <Layout>
    <Helmet>
      <title>Daten-Strom-GP | Datenschutz</title>
      <meta name="description" content="Daten-Strom-GP - Datenschutz" />
    </Helmet>
    <section className="pt-5 md:pt-20 shadow-lg bg-static-sites text-white px-5">
      <div className="container mx-auto pb-3">
        <h1>Datenschutzerklärung</h1>
        <Link className="text-white hover:text-white hover:underline" to="/">zurück zur Startseite</Link>
      </div>
    </section>
    <section className="pt-5 px-5">
      <div className="container mx-auto" dangerouslySetInnerHTML={{__html: data.file.childHtmlRehype.html}} />
    </section>
  </Layout>
);

// export page query
export const query = graphql`
  query PrivacyContent {
    file(name: {eq: "datenschutz"}) {
      childHtmlRehype {
        html
      }
    }
  }
`

export default Datenschutz;
